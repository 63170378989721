import { useQuery } from 'data-fetching-client';
import { GET_CURRENT_USER_CAPACITY_DATA_QUERY } from '../../../__generated__/chirp/com/hubspot/cv/assignments/rpc/UserCapacityLimitsV2Service.dfc';
import { useIsUngatedForUserLevelCapacityLimitsTicketType } from '../../auth/hooks/useGates';
import { useHasCapacityLimitsV2ReadScope } from '../../auth/hooks/useScopes';
export const useGetCurrentUserCapacityDataRpc = ({
  objectTypeId
}) => {
  const isUngatedForUserLevelCapacityLimitsTicketType = useIsUngatedForUserLevelCapacityLimitsTicketType();
  const hasCapacityLimitsV2ReadScope = useHasCapacityLimitsV2ReadScope();
  const {
    data,
    loading,
    error
  } = useQuery(GET_CURRENT_USER_CAPACITY_DATA_QUERY, {
    skip: !hasCapacityLimitsV2ReadScope || !isUngatedForUserLevelCapacityLimitsTicketType,
    variables: {
      objectTypeId
    }
  });
  return {
    currentUserCapacity: data === null || data === void 0 ? void 0 : data.getCurrentUserCapacityData,
    loading,
    error
  };
};