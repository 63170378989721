import { useQuery } from 'data-fetching-client';
import { GET_USER_CAPACITY_DATA_QUERY } from '../../../__generated__/chirp/com/hubspot/cv/assignments/rpc/UserCapacityLimitsV2Service.dfc';
import { useMemo } from 'react';
import { useHasCapacityLimitsV2ReadScope } from '../../auth/hooks/useScopes';
import { getUserCapacityData, defaultLimitToInfinity } from '../utils/capacityUtils';
import { useGetCurrentUserCapacityDataRpc } from './useGetCurrentUserCapacityDataRpc';
export const useDeprecatedUserLevelCapacityLimits = agentId => {
  const hasCapacityLimitsV2ReadScope = useHasCapacityLimitsV2ReadScope();
  const {
    data,
    loading
  } = useQuery(GET_USER_CAPACITY_DATA_QUERY, {
    skip: !hasCapacityLimitsV2ReadScope,
    variables: {
      userId: agentId
    }
  });
  const capacityLimits = useMemo(() => {
    if (!data) {
      return;
    }
    return defaultLimitToInfinity(data.getUserCapacityData.totalTicketUserCapacityData);
  }, [data]);
  return {
    capacityLimits,
    loading
  };
};
export const useCurrentUserCapacityLimits = ({
  objectTypeId
}) => {
  const {
    currentUserCapacity,
    loading,
    error
  } = useGetCurrentUserCapacityDataRpc({
    objectTypeId
  });
  if (!currentUserCapacity) {
    return {
      loading,
      error
    };
  }
  return {
    capacity: getUserCapacityData(currentUserCapacity),
    loading,
    error
  };
};