export const visit = (vm, at) => {
vm(require("calling-integration-help-desk/lang/en.lyaml"));
vm(require("./attachments/en.lyaml"));
vm(require("./capability-editor/en.lyaml"));
vm(require("./compose/en.lyaml"));
vm(require("./disable-send/en.lyaml"));
vm(require("./editor-utilities/en.lyaml"));
vm(require("./metadata-editor/en.lyaml"));
vm(require("./pop-out/en.lyaml"));
vm(require("./prosemirror/en.lyaml"));
vm(require("./reply-recommendations/en.lyaml"));
vm(require("./send-button/en.lyaml"));
vm(require("./tabbed-editor/en.lyaml"));
vm(require("./whatsapp/en.lyaml"));
vm(require("conversations-error-reporting/lang/en.lyaml"));
vm(require("conversations-thread-view/lang/en.lyaml"));
vm(require("hubspot-prosemirror/lang/en.lyaml"));
vm(require("composer-data/lang/en.lyaml"));
vm(require("UIComponents/lang/en.lyaml"));
vm(require("./alerts/en.lyaml"));
at({ "en": { "composer": { "title": "Composer", "empty-disabled-message": "The editor has encountered an unknown error. Please contact support." } } });
};