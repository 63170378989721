export const visit = (vm, at) => {
vm(require("UIComponents/lang/en.lyaml"));
vm(require("SharedI18nStrings/lang/countryCodes/en.lyaml"));
vm(require("SharedI18nStrings/lang/languageNames/en.lyaml"));
vm(require("ui-addon-i18n/lang/en.lyaml"));
vm(require("ui-addon-upgrades/lang/en.lyaml"));
vm(require("timezone-utils/lang/en.lyaml"));
vm(require("routing-ui/lang/features/users-and-teams/en.lyaml"));
vm(require("./routing-configuration/en.lyaml"));
vm(require("./user-working-hours/en.lyaml"));
vm(require("./user-working-hours-read-out/en.lyaml"));
vm(require("./custom-messaging-voicemail/en.lyaml"));
vm(require("./hubspot-number-acquire/en.lyaml"));
vm(require("./numbers-table/en.lyaml"));
vm(require("./numbers-table-header/en.lyaml"));
vm(require("./forwarding-number-guidance/en.lyaml"));
vm(require("./calls-v1-settings-numbers/en.lyaml"));
vm(require("./number-nickname/en.lyaml"));
vm(require("calling-custom-messages-library/lang/en.lyaml"));
vm(require("calling-hubspot-numbers-acquisition-library/lang/en.lyaml"));
vm(require("./assignable-users/en.lyaml"));
vm(require("./number-edit/en.lyaml"));
vm(require("./calls-v1-settings-usage/en.lyaml"));
at({ "en": { "calling-number-and-routing-settings-library": { "hubspot-number-lookup": { "sub-title-inbound": "Set up a new phone number for making and receiving calls in HubSpot.", "form-title": "Generate a phone number", "acquire-number": "Get this number" }, "withErrorBoundary": { "title": "An error occurred.", "message": "Please refresh the page." }, "validation-message": { "error-ada": "Error:" } } } });
};