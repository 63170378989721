export const visit = (vm, at) => {
vm(require("calling-integration-help-desk/lang/en.lyaml"));
vm(require("./custom-views/en.lyaml"));
vm(require("./ticket-header/en.lyaml"));
vm(require("./ticket-toolbar/en.lyaml"));
vm(require("./preview-card/en.lyaml"));
vm(require("./navigation-sidebar/en.lyaml"));
vm(require("UIComponents/lang/en.lyaml"));
vm(require("find-and-filter-data/lang/en.lyaml"));
at({ "en": { "svh-workspace-ui-lib": { "owner-filter": { "owner": "Owner:", "all": "All", "allTickets": "All tickets", "me": "Me", "unassigned": "Unassigned" }, "alerts": { "RETRY": { "message_jsx": function (createElement, options, props) { return createElement( options, "wrapper", null, "There was a network connection issue.", createElement( options, "br", null ), " Please check your connection and ", createElement( options, "UILink", props, "try again" ), "." ); }, "button": "Try again" } }, "requireElevatedJita": { "title": "You don't have permission to view email activity.", "paragraph": "To view it, you'll need to request elevated just-in-time access. <a href='https://wiki.hubspotcentral.net/display/~kspuglio/Changes+to+Elevated+JITA%3A+1%3A1+Email+and+Conversations' target='_blank' rel='noopener'>Learn More</a>", "buttonText": "Request elevated access" }, "unauthenticated-domains": { "title": { "one": "{{ count }} email account is at risk of not getting delivered", "other": "{{ count }} email accounts are at risk of not getting delivered" }, "admin-details": "Take action to make sure your emails are sent from your chosen 'from address' and received by your contacts.", "rep-details": "Contact your admin to authenticate these email sending domains to avoid deliverability problems.", "learn-more": "Learn more", "button": "See affected accounts" } } } });
};