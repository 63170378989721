export const AWAY_MODE_CHANGE = 'AWAY_MODE_CHANGE';
export const USER_CAPACITY_LIMIT_V2_UPDATE = 'USER_CAPACITY_LIMIT_V2_UPDATE';

// Capacity Category constants
export const CAPACITY_CATEGORIES = {
  ASYNC_COMMUNICATION: 'ASYNC_COMMUNICATION',
  CALLING: 'CALLING',
  REAL_TIME_MESSAGING: 'REAL_TIME_MESSAGING'
};
// Capacity Config Type constants
export const CAPACITY_CONFIG_TYPES = {
  TOTAL: 'TOTAL',
  BY_CAPACITY_CATEGORY: 'BY_CAPACITY_CATEGORY'
};