import PortalIdParser from 'PortalIdParser';
import { COMMERCE_PAYMENT_TYPE_ID, DEAL_TYPE_ID, PRODUCT_TYPE_ID, SUBSCRIPTION_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { LINE_ITEM, PRODUCT } from 'customer-data-objects/constants/ObjectTypes';
import { isEmail } from 'customer-data-objects/property/PropertyIdentifier';
import enviro from 'enviro';
export const isEditableDealExchangeRate = (property, objectTypeId) => objectTypeId === DEAL_TYPE_ID && property.name === 'hs_exchange_rate' && !property.calculated;
export const isSettlementCurrencyProperty = (property, objectTypeId) => objectTypeId === COMMERCE_PAYMENT_TYPE_ID && (property.name === 'hs_fees_amount' || property.name === 'hs_platform_fee' || property.name === 'hs_net_amount' || property.name === 'hs_initial_amount' || property.name === 'hs_refunds_amount');
export const isTaxCategoryProperty = (property, objectType) => (objectType === PRODUCT || objectType === LINE_ITEM) && property.name === 'hs_tax_category';

// This is a special case for the BET portal where we want to use the email validation for 'primary_point_of_contact_email' property.
// isEmail will be extended to use an identifier (probably 'validationHint') in property definition
export const isRevenueEmail = (propertyDefinition, objectType) => {
  const BETPortalId = enviro.isQa() ? 99535353 : 53;
  return BETPortalId === PortalIdParser.get() && isEmail(propertyDefinition, objectType);
};

// In PropertyInputV2, for subscription objects, treat hubspotDefined properties as read-only,
// except for hs_name and hubspot_owner_id, which should remain editable.
// See https://hubspot.slack.com/archives/C0779K5FNJU/p1720732734377819
export const isReadOnlyBillableSubscriptionProperty = (objectTypeId, propertyDefinition) => {
  return objectTypeId === SUBSCRIPTION_TYPE_ID && propertyDefinition.hubspotDefined && !['hs_name', 'hubspot_owner_id'].includes(propertyDefinition.name);
};

/**
 * Identifies enum properties that should not display blank options in UI selects,
 * even when not marked as required. Used when we can't mark properties as required
 * but still want to prevent blank selections, and can't use default values.
 */
export const isEnumWithoutBlankOption = (objectTypeId, property) => {
  if (objectTypeId === PRODUCT_TYPE_ID) {
    if (property.name === 'hs_status') {
      return true;
    }
  }
  return false;
};