export const visit = (vm, at) => {
vm(require("sanitize-text/lang/en.lyaml"));
vm(require("calling-ui-library/lang/en.lyaml"));
vm(require("conversations-calling-message/lang/en.lyaml"));
vm(require("./assignment/en.lyaml"));
vm(require("./attachments/en.lyaml"));
vm(require("./avatar/en.lyaml"));
vm(require("./call-status/en.lyaml"));
vm(require("./common-message/en.lyaml"));
vm(require("./contact-promotion-modal/en.lyaml"));
vm(require("./crm-ticket-lifecycle-update/en.lyaml"));
vm(require("./crm-object-workflow-email-sent/en.lyaml"));
vm(require("./context-update-message/en.lyaml"));
vm(require("./customer-agent-action-execution/en.lyaml"));
vm(require("./delivery-status/en.lyaml"));
vm(require("./email-capture/en.lyaml"));
vm(require("./email-header/en.lyaml"));
vm(require("./filtered-change/en.lyaml"));
vm(require("./initial-message/en.lyaml"));
vm(require("./responder/en.lyaml"));
vm(require("./status/en.lyaml"));
vm(require("./ticket-creation-failure/en.lyaml"));
vm(require("./transcript-submission/en.lyaml"));
vm(require("./workflow-generated-user-message/en.lyaml"));
vm(require("./batch-sms-generated-user-message/en.lyaml"));
vm(require("./message-preview/en.lyaml"));
vm(require("./helpdesk-ticket-split-update-message/en.lyaml"));
at({ "en": { "conversations-inbox-message-history": { "commentMessageSubject": "{{ name }} left a comment {{ timestamp }}", "commentMessageTooltip": "{{ icon }} Only visible to your team", "commentActions": { "actions": "Actions", "cancel": "Cancel", "dialogBody": "Delete this comment?", "dialogFooter": "Once you delete this comment, it will be removed from the thread. This can't be undone.", "deleteCommentButton": "Delete Comment" }, "at-time": "at {{ timestamp }}", "availability": { "small": "Not what you expected?", "tooltip": "Your return time was adjusted based on your visitor's time zone." }, "new": "New" } } });
};