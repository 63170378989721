export const Scopes = Object.freeze({
  user: {
    jitaUser: 'jita-user' // https://tools.hubteamqa.com/launch/scopes/1399/parcels
  },
  subscription: {
    /** Used to control a users' ability to access subscription pages in the UI.
     * this scope is role-irrelevant and will be given to every user if the portal has this scope.
     * https://tools.hubteamqa.com/launch/scopes/2201/parcels
     */
    access: 'subscriptions-access',
    /** Used to control a users' ability to create, update, and cancel subscriptions.
     * https://tools.hubteamqa.com/launch/scopes/2200/parcels
     */
    write: 'subscriptions-write',
    /** Used to control a users' ability to view specific subscription data in the UI. If a user does not have read scope,
     * they will see 'no access' when attempting to view subscriptions
     * https://tools.hubteamqa.com/launch/scopes/2199/parcels
     */
    read: 'subscriptions-read',
    /** Used to control a users' ability to cancel subscriptions.
     * https://tools.hubteamqa.com/launch/scopes/5694/parcels
     */
    delete: 'subscriptions-delete'
  },
  invoices: {
    /** Used to control a users' ability to view invoices.
     * https://tools.hubteamqa.com/launch/scopes/2196/parcels
     */
    read: 'invoices-read'
  },
  payments: {
    /** Used to control a users' ability to view payments.
     * https://tools.hubteamqa.com/launch/scopes/1896/parcels
     */
    read: 'payments-read'
  }
});

/**
 * @deprecated Use Scopes instead */
export const SCOPES = Object.freeze({
  /**
   * @deprecated Use Scopes.user.jitaUser instead
   */
  JITA_USER: Scopes.user.jitaUser,
  /**
   * @deprecated Use Scopes.subscription.access instead
   */
  SUBSCRIPTIONS_ACCESS: Scopes.subscription.access,
  /**
   * @deprecated Use Scopes.subscription.write instead
   */
  SUBSCRIPTIONS_WRITE: Scopes.subscription.write,
  /**
   * @deprecated Use Scopes.invoices.read instead
   */
  INVOICES_READ: Scopes.invoices.read
});
export const URLS = Object.freeze({
  KBCancelSubscription: 'https://knowledge.hubspot.com/payments/manage-subscriptions-for-recurring-payments#cancel-subscriptions',
  KBUpdatePaymentMethod: 'https://knowledge.hubspot.com/payments/update-payment-method-for-failed-subscription-payments',
  KBUpdateBouncedEmail: 'https://knowledge.hubspot.com/email/how-can-i-send-an-email-to-a-contact-that-has-previously-bounced#how-hubspot-handles-bounced-contacts',
  KBEmailConfirmation: 'https://knowledge.hubspot.com/payments/edit-subscriptions#email-confirmation',
  KBPause: 'https://knowledge.hubspot.com/payments/edit-subscriptions#pause-and-resume-a-subscription',
  KBPauseTooltip: 'https://knowledge.hubspot.com/payments/manage-subscriptions-for-recurring-payments',
  KBDeleteSubscription: 'https://knowledge.hubspot.com/subscriptions/manage-subscriptions-for-recurring-payments#delete-a-subscription'
});
export const TEST_IDS = Object.freeze({
  Cancel: 'subscription-cancellation-modal',
  EditNextBillingDate: 'edit-next-payment-date-modal',
  UpdatePaymentMethod: 'update-payment-method-modal',
  Pause: 'pause-modal',
  Resume: 'resume-modal',
  Delete: 'delete-modal'
});
export const IS_KITCHEN_SINK = window.location.pathname.includes('kitchen-sink');
export const EMPTY_VALUE = '--';
export const SUBSCRIPTION_SETTINGS_TIP_ID = 'commerce-subscription-lib:subscription-settings-tip';