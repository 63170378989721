import { readUnhandledEnumCase } from 'rpc-client-utils';
import { CAPACITY_CATEGORIES, CAPACITY_CONFIG_TYPES } from '../constants';
export const defaultLimitToInfinity = ({
  capacityLimit,
  capacityLoad
}) => ({
  capacityLimit: capacityLimit !== null && capacityLimit !== void 0 ? capacityLimit : Infinity,
  capacityLoad
});
export const isCapacityFull = capacity => {
  var _capacity$capacityLim;
  return !!capacity && capacity.capacityLoad >= ((_capacity$capacityLim = capacity.capacityLimit) !== null && _capacity$capacityLim !== void 0 ? _capacity$capacityLim : Infinity);
};
export const hasLimit = capacity => {
  return !!capacity && typeof capacity.capacityLimit === 'number';
};

// validate configuration type enum, default to TOTAL
export const getConfigurationType = configurationType => {
  const checkedString = configurationType && readUnhandledEnumCase(configurationType);
  switch (checkedString) {
    case CAPACITY_CONFIG_TYPES.TOTAL:
    case CAPACITY_CONFIG_TYPES.BY_CAPACITY_CATEGORY:
      return checkedString;
    default:
      return CAPACITY_CONFIG_TYPES.TOTAL;
  }
};

// validate category enums
export const getCapacityByCategory = capacityByCategory => ({
  ASYNC_COMMUNICATION: capacityByCategory[CAPACITY_CATEGORIES.ASYNC_COMMUNICATION],
  CALLING: capacityByCategory[CAPACITY_CATEGORIES.CALLING],
  REAL_TIME_MESSAGING: capacityByCategory[CAPACITY_CATEGORIES.REAL_TIME_MESSAGING]
});
export const getUserCapacityData = userCapacityData => {
  const type = getConfigurationType(userCapacityData.capacityConfigurationType);
  switch (type) {
    case 'BY_CAPACITY_CATEGORY':
      return {
        type,
        capacity: getCapacityByCategory(userCapacityData.categoryUserCapacityData)
      };
    case 'TOTAL':
      return {
        type,
        capacity: userCapacityData.totalTicketUserCapacityData
      };
    default:
      return null;
  }
};