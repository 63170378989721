import { LINE_ITEM_TYPE_ID, PRODUCT_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { getCurrencyCodeForPriceProperty } from 'customer-data-objects/lineItem/PropertyNames';
import { isMultiCurrencyPrice } from 'customer-data-objects/property/PropertyIdentifier';
export const getEffectiveTieredPriceCurrencyCode = (objectTypeId, property, homeCurrencyCode, lineItemCurrencyCode) => {
  if (objectTypeId === PRODUCT_TYPE_ID) {
    const isMulticurrencyActive = isMultiCurrencyPrice(property);
    if (isMulticurrencyActive) {
      var _getCurrencyCodeForPr;
      return (_getCurrencyCodeForPr = getCurrencyCodeForPriceProperty(property.name)) !== null && _getCurrencyCodeForPr !== void 0 ? _getCurrencyCodeForPr : homeCurrencyCode;
    }

    // Returns undefined for single currency product portals as tiered pricing won't have a currency
    return undefined;
  } else if (objectTypeId === LINE_ITEM_TYPE_ID) {
    return lineItemCurrencyCode !== null && lineItemCurrencyCode !== void 0 ? lineItemCurrencyCode : homeCurrencyCode;
  }
  return homeCurrencyCode;
};