import UIButton from 'UIComponents/button/UIButton';
import { GRADIENT, MAGENTA, BLACK, WHITE } from '../../../../styles/colors';
import styled, { css } from 'styled-components';
import { BREEZE_SPECIAL } from '../../../../constants/BreezeUse';
const DropdownButtonIconStyle = css([".uiDropdown__buttonContents{display:inline-block;.uiDropdown__buttonLabel{margin-right:0.5rem;}}"]);
const IconStyle = css([".ai-icon{position:relative;bottom:1px;}"]);
export const BreezePrimaryAIBaseButton = styled(UIButton).withConfig({
  displayName: "StyledAIBaseButton__BreezePrimaryAIBaseButton",
  componentId: "m4jzg9-0"
})(["border-radius:2rem !important;color:", " !important;border:none !important;background:", " !important;&:hover{background-color:", " !important;}&:active{background-color:", " !important;}", " ", ""], WHITE, MAGENTA[400], MAGENTA[500], MAGENTA[600], DropdownButtonIconStyle, IconStyle);
export const BreezeSecondaryAIBaseButton = styled(UIButton).withConfig({
  displayName: "StyledAIBaseButton__BreezeSecondaryAIBaseButton",
  componentId: "m4jzg9-1"
})(["border-radius:2rem !important;color:", " !important;border-color:", " !important;background:", " !important;&:hover{color:", " !important;border-color:", " !important;background:", " !important;}&:active{color:", " !important;border-color:", " !important;background:", " !important;}", " ", ""], MAGENTA[400], MAGENTA[400], WHITE, MAGENTA[500], MAGENTA[500], MAGENTA[100], MAGENTA[600], MAGENTA[600], MAGENTA[200], DropdownButtonIconStyle, IconStyle);
export const BreezeTertiaryAIBaseButton = styled(UIButton).withConfig({
  displayName: "StyledAIBaseButton__BreezeTertiaryAIBaseButton",
  componentId: "m4jzg9-2"
})(["border-radius:2rem !important;color:", " !important;border-color:", " !important;background:", " !important;&:hover{color:", " !important;border-color:", " !important;}&:active{color:", " !important;border-color:", " !important;}", " ", ""], BLACK.NEUTRAL, BLACK.NEUTRAL, WHITE, BLACK['HOVER'], BLACK['HOVER'], BLACK['ACTIVE'], BLACK['ACTIVE'], DropdownButtonIconStyle, IconStyle);
export const BreezeSpecialAIBaseButton = styled(UIButton).withConfig({
  displayName: "StyledAIBaseButton__BreezeSpecialAIBaseButton",
  componentId: "m4jzg9-3"
})(["border-radius:2rem !important;&:not(.disabled){background:", ";color:", " !important;border:none !important;&:hover{background:", ";}&:active{background:", ";}&.disabled{background:none;}}", " ", ""], GRADIENT['DARK'], WHITE, MAGENTA[500], MAGENTA[600], DropdownButtonIconStyle, IconStyle);
export const BreezeTransparentAIBaseButton = styled(UIButton).withConfig({
  displayName: "StyledAIBaseButton__BreezeTransparentAIBaseButton",
  componentId: "m4jzg9-4"
})(["border-radius:2rem !important;border:none !important;color:", " !important;&:not(.disabled) .ai-icon,&:not(.disabled) span{color:", " !important;}&:hover{color:", " !important;}&:active{color:", " !important;}", " ", ""], MAGENTA[400], MAGENTA[400], MAGENTA[500], MAGENTA[600], DropdownButtonIconStyle, IconStyle);
export const getStyledAIButton = use => {
  switch (use) {
    case BREEZE_SPECIAL:
      return BreezeSpecialAIBaseButton;
    case 'primary':
      return BreezePrimaryAIBaseButton;
    case 'secondary':
      return BreezeSecondaryAIBaseButton;
    case 'tertiary':
      return BreezeTertiaryAIBaseButton;
    case 'transparent':
      return BreezeTransparentAIBaseButton;
    default:
      return BreezePrimaryAIBaseButton;
  }
};