import { denormalizeTypeId } from 'customer-data-objects/constants/ObjectTypeIds';
import { HIDDEN } from 'customer-data-objects/property/AccessLevel';
import { isBillingFrequency, isContactEmail, isCreateCloseDate, isCurrency, isDate, isDomain, isDuration, isEnum, isFile, isFileUpload, isGuidPropertyWithKnownGuid, isHtml, isMultiCurrencyCurrencyCode, isMultiCurrencyPrice, isNumber, isPercent, isPhoneNumber, isPipelineProperty, isPipelineStageProperty, isProbability, isCheckbox, isTermBillingPeriod, isProductUrl, isTextarea, isDateTimeWithHiddenTime } from 'customer-data-objects/property/PropertyIdentifier';
import { RequirablePropertyInputCloseCreateDate, RequirablePropertyInputDate, RequirablePropertyInputDuration, RequirablePropertyInputEmailV2, RequirablePropertyInputEnum, RequirablePropertyInputExpandableText, RequirablePropertyInputFiles, RequirablePropertyInputHidden, RequirablePropertyInputHtml, RequirablePropertyInputKnownGuid, RequirablePropertyInputNumber, RequirablePropertyInputPercentage, RequirablePropertyInputPipelineV2, RequirablePropertyInputPipelineStageV2, RequirablePropertyInputSettlementCurrency, RequirablePropertyInputPrice, RequirablePropertyInputReadOnly, RequirablePropertyInputCheckbox, RequirablePropertyInputMultiCurrencyCurrencyCodeV2, RequirablePropertyInputDealExchangeRateWrapper, RequirablePropertyInputUrl, RequirablePropertyInputFilesV2, RequirablePropertyInputDateTime, RequirablePropertyInputTextArea, RequirablePropertyInputTaxCategory, RequireablePropertyInputAISkeleton, RequirablePropertyInputBillingPeriod } from '../../input/PropertyInputGetters';
import BillingFrequencyPropertyInputEnumShim from '../shims/BillingFrequencyPropertyInputEnumShim';
import PropertyInputCurrencyShimV2 from '../shims/PropertyInputCurrencyShimV2';
import PropertyInputMultipleDomainShim from '../shims/PropertyInputMultipleDomainShim';
import PropertyInputMultipleEmailV2Shim from '../shims/PropertyInputMultipleEmailV2Shim';
import shouldDisplayCheckbox from '../../input/checkbox/utils/shouldDisplayCheckbox';
import PropertyInputExternalOptionShim from '../shims/PropertyInputExternalOptionShim';
import { getPropertyComponentResolver } from '../../resolvers/getPropertyComponentResolver';
import PropertyInputRequiredEnumShim from '../../input/PropertyInputRequiredEnumShim';
import { isEditableDealExchangeRate, isEnumWithoutBlankOption, isRevenueEmail, isSettlementCurrencyProperty, isTaxCategoryProperty } from './identifiers';
import { shouldShowPopovers } from './shouldShowPopovers';
import { isDateTime } from 'customer-data-objects/property/PropertyIdentifier';
import PropertyInputPhoneShim from '../shims/PropertyInputPhoneShim';
import shouldShowAIComponentForStatus from '../../ai/shouldShowAIComponentForStatus';
export const getPropertyInputComponent = ({
  accessLevel,
  enableMultipleEmailInput,
  enablePopovers,
  enableCheckboxInput,
  enablePhoneNumberInput,
  getAdditionalPropertyValue,
  httpClient,
  objectId,
  objectTypeId,
  property,
  propertyValue,
  readOnly,
  shouldUseOptionColors,
  aiProcessingStatus
}) => {
  const objectType = denormalizeTypeId(objectTypeId);

  // If the user doesn't have permissions to view the property we always show
  // the hidden
  if (accessLevel === HIDDEN) {
    return RequirablePropertyInputHidden;
  }
  if (shouldShowAIComponentForStatus(aiProcessingStatus)) {
    return RequireablePropertyInputAISkeleton;
  }
  const resolver = getPropertyComponentResolver({
    getAdditionalPropertyValue,
    property,
    objectId,
    objectTypeId,
    httpClient,
    value: propertyValue
  });

  //////////////////// START OF RESOLVER INPUTS ////////////////////////////////

  // uses RRL
  if (isPipelineProperty(property, objectTypeId)) {
    return RequirablePropertyInputPipelineV2;
  }

  // uses RRL
  if (isPipelineStageProperty(property, objectTypeId)) {
    return RequirablePropertyInputPipelineStageV2;
  }
  if (isMultiCurrencyCurrencyCode(property, objectType)) {
    return RequirablePropertyInputMultiCurrencyCurrencyCodeV2;
  }
  if (isFile(property)) {
    return RequirablePropertyInputFilesV2;
  }
  if (isTaxCategoryProperty(property, objectType)) {
    return RequirablePropertyInputTaxCategory;
  }

  // If we don't have a custom input, default to a standard
  // reference-resolvers-lite select
  if (resolver) {
    return PropertyInputExternalOptionShim;
  }

  //////////////////// END OF RESOLVER INPUTS //////////////////////////////////

  if (shouldShowPopovers({
    enablePopovers,
    objectId
  }) && isDomain(property, objectType)) {
    return PropertyInputMultipleDomainShim;
  }
  if (
  // multi-email input requires extra `actions` (API callbacks) that most
  // caller callers don't provide. it requires explicit opt-in via
  // `enableMultipleEmailInput` even when popovers are enabled
  enableMultipleEmailInput && shouldShowPopovers({
    enablePopovers,
    objectId
  }) && isContactEmail(property, objectType)) {
    return PropertyInputMultipleEmailV2Shim;
  }
  if (isContactEmail(property, objectType)) {
    return RequirablePropertyInputEmailV2;
  }
  if (isRevenueEmail(property, objectType)) {
    return RequirablePropertyInputEmailV2;
  }

  // Percent properties are number properties so need to preempt it
  if (isPercent(property) || isProbability(property)) {
    return RequirablePropertyInputPercentage;
  }

  // Duration properties are number properties so need to preempt it
  if (isDuration(property)) {
    return RequirablePropertyInputDuration;
  }
  if (isSettlementCurrencyProperty(property, objectTypeId)) {
    return RequirablePropertyInputSettlementCurrency;
  }
  if (isMultiCurrencyPrice(property)) {
    return RequirablePropertyInputPrice;
  }
  if (isCurrency(property)) {
    return PropertyInputCurrencyShimV2;
  }
  if (isTermBillingPeriod(property, objectTypeId)) {
    return RequirablePropertyInputBillingPeriod;
  }

  // Html properties render their own readonlys (for previewing in the RTE)
  if (isHtml(property)) {
    return RequirablePropertyInputHtml;
  }

  // NOTE: even though phone number input is a popover, it is not affected
  //       by the `enablePopovers` flag because "simple" forms such as the
  //       record creator have historically shown it. callers can opt-out
  //       separately via `enablePhoneNumberInput`
  if (enablePhoneNumberInput && isPhoneNumber(property)) {
    return PropertyInputPhoneShim;
  }
  if (isEditableDealExchangeRate(property, objectTypeId)) {
    return RequirablePropertyInputDealExchangeRateWrapper;
  }

  // numbers can render their own readonlys
  if (isNumber(property)) {
    return RequirablePropertyInputNumber;
  }
  if (isGuidPropertyWithKnownGuid(property, objectType, propertyValue)) {
    return RequirablePropertyInputKnownGuid;
  }
  if (isCheckbox(property) && shouldDisplayCheckbox(property, objectType, enableCheckboxInput)) {
    return RequirablePropertyInputCheckbox;
  }
  if (isProductUrl(property, objectTypeId)) {
    return RequirablePropertyInputUrl;
  }
  if (readOnly && !shouldUseOptionColors) {
    if (isFileUpload(property)) {
      return RequirablePropertyInputFiles;
    }
    return RequirablePropertyInputReadOnly;
  }

  // specifically after 'read only' so that PropertyInputReadOnly will display
  // comma-separated lists instead of a disabled line of multi-select pills
  if (isEnum(property)) {
    if (isBillingFrequency(property, objectTypeId)) {
      return BillingFrequencyPropertyInputEnumShim;
    }
    if (isEnumWithoutBlankOption(objectTypeId, property)) {
      return PropertyInputRequiredEnumShim;
    }
    return RequirablePropertyInputEnum;
  }

  // specifically after 'read only' so that PropertyInputReadOnly will display
  // formatted date and datetime strings in readonly contexts
  if (isCreateCloseDate(property, objectType)) {
    return RequirablePropertyInputCloseCreateDate;
  }

  // specifically after 'read only' so that PropertyInputReadOnly will display
  // formatted datetime strings in readonly contexts
  if (isDateTime(property)) {
    return isDateTimeWithHiddenTime(property, objectTypeId) ? RequirablePropertyInputDate : RequirablePropertyInputDateTime;
  }

  // specifically after 'read only' so that PropertyInputReadOnly will display
  // formatted date and datetime strings in readonly contexts
  if (isDate(property)) {
    return RequirablePropertyInputDate;
  }
  if (isTextarea(property)) {
    return RequirablePropertyInputTextArea;
  }

  // anything else defaults to `text` through the expandable text input
  return RequirablePropertyInputExpandableText;
};