import PropertyRecord from 'customer-data-objects/property/PropertyRecord';
import memoize from 'transmute/memoize';
export const parseProductProperties = memoize(propertyGroupsGql => {
  if (!propertyGroupsGql) {
    return new Map();
  }

  // Flatten array of property groups into array of property definitions
  const propertiesArray = propertyGroupsGql.flatMap(propertyGroup => propertyGroup.allPropertyDefinitions);

  // Create properties map
  const propertiesMap = new Map(propertiesArray.map(property => [property === null || property === void 0 ? void 0 : property.name, PropertyRecord.fromJS(property)]));
  return propertiesMap;
});
export const parseProductData = memoize(productDetails => {
  var _productDetails$crmOb;
  if (productDetails !== null && productDetails !== void 0 && (_productDetails$crmOb = productDetails.crmObject) !== null && _productDetails$crmOb !== void 0 && (_productDetails$crmOb = _productDetails$crmOb.allProperties) !== null && _productDetails$crmOb !== void 0 && _productDetails$crmOb.length) {
    return {
      objectId: productDetails.crmObject.id,
      properties: productDetails.crmObject.allProperties.reduce((acc, {
        name,
        value
      }) => {
        acc[name] = {
          name,
          value: value !== null && value !== void 0 ? value : undefined
        };
        return acc;
      }, {})
    };
  }
  return undefined;
});