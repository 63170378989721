import { PAGE_TITLE_FONT_SIZE } from 'HubStyleTokens/sizes';
import { PAGE_TITLE_FONT_WEIGHT } from 'HubStyleTokens/misc';
import styled from 'styled-components';
import { FONT_FAMILIES } from '../../utils/Styles';
import Heading from '../Heading';
const PageHeading = styled(Heading).withConfig({
  displayName: "PageHeading",
  componentId: "sc-1bk9dz1-0"
})(["font-size:", ";", ";margin:0;"], PAGE_TITLE_FONT_SIZE, FONT_FAMILIES[PAGE_TITLE_FONT_WEIGHT]);
PageHeading.propTypes = Heading.propTypes;

/* eslint-disable-next-line hubspot-dev/no-default-props-assignment */
PageHeading.defaultProps = Object.assign({}, Heading.defaultProps, {
  'aria-level': 1,
  tagName: 'h1'
});
PageHeading.displayName = 'PageHeading';
export default PageHeading;