import { HS_AUTO_TAX_AMOUNT, HS_POST_TAX_AMOUNT, HS_TAX_AMOUNT, HS_TAX_LABEL, HS_TAX_RATE } from '../constants/properties';
import { makeNewProperty } from './property';
import { getProperty } from 'customer-data-objects/objects/ObjectAccessors';
import { HS_ENABLE_AUTOMATED_TAXES } from 'invoices-ui-lib/constants/properties';
export const removeAutomatedSalesTaxLineItemProperties = lineItems => lineItems.map(lineItem => Object.assign({}, lineItem, {
  properties: Object.assign({}, lineItem.properties, {
    [HS_AUTO_TAX_AMOUNT]: makeNewProperty(HS_AUTO_TAX_AMOUNT, undefined),
    [HS_TAX_AMOUNT]: makeNewProperty(HS_TAX_AMOUNT, undefined),
    [HS_TAX_RATE]: makeNewProperty(HS_TAX_RATE, undefined),
    [HS_POST_TAX_AMOUNT]: makeNewProperty(HS_POST_TAX_AMOUNT, undefined),
    [HS_TAX_LABEL]: makeNewProperty(HS_TAX_LABEL, undefined)
  })
}));
export const hasManualTaxes = lineItems => lineItems.some(lineItem => {
  const lineItemTaxRateGroup = getProperty(lineItem, 'hs_tax_rate_group_id');
  return lineItemTaxRateGroup != null && lineItemTaxRateGroup !== '';
});
export function getAutomatedSalesTaxProperties(lineItems) {
  if (!hasManualTaxes(lineItems)) {
    return {};
  }
  return {
    [HS_ENABLE_AUTOMATED_TAXES]: makeNewProperty(HS_ENABLE_AUTOMATED_TAXES, 'false')
  };
}