export const visit = (vm, at) => {
vm(require("conversations-routing-lib/lang/en.lyaml"));
vm(require("file-manager-components/lang/en.lyaml"));
vm(require("routing-ui/lang/en.lyaml"));
vm(require("ui-addon-upgrades/lang/en.lyaml"));
vm(require("./facebook-messenger/en.lyaml"));
vm(require("./email-configuration/en.lyaml"));
vm(require("./channel-instances/en.lyaml"));
vm(require("./custom-channel-edit/en.lyaml"));
vm(require("./send-from-address/en.lyaml"));
vm(require("./rich-text-editor/en.lyaml"));
vm(require("./routing/en.lyaml"));
vm(require("./channel-connect/en.lyaml"));
vm(require("./live-chat-settings/en.lyaml"));
vm(require("./sms-onboarding-wizard/en.lyaml"));
vm(require("./sms-inbox-connect/en.lyaml"));
vm(require("./default-send-from/en.lyaml"));
vm(require("skills-lib/lang/en.lyaml"));
at({ "en": { "channel-settings": { "title": "Channel settings", "save-bar": { "pending-changes": { "one": "You've changed {{ count }} setting.", "other": "You've changed {{ count }} settings." }, "cannot-save-tooltip": "Complete required fields to save changes.", "save-button": "Save", "cancel-button": "Cancel" } } } });
};