/**
 *
 * Sets the tooltip for the specified property.
 * If the map contains top level property groups, it'll loop through the properties in each group
 * and set the tooltip for the property that matches the property name in the propertyNameToSetTooltip.
 * @param {ImmutableMap} originalFields - The original properties list.
 * @param {string} propertyNameToSetTooltip - The property name to set the tooltip for.
 * @param {string} tooltip - The tooltip to set for the property.
 */
export const setCustomPropertyTooltip = ({
  originalFields,
  propertyNameToSetTooltip,
  tooltip
}) => {
  return originalFields.map(property => {
    //If property is a group, parse out the properties list and look for the property in there.
    if (property.get('properties')) {
      return property.update('properties', properties => {
        return properties.map(nestedProperty => {
          if (propertyNameToSetTooltip === nestedProperty.get('name')) {
            return nestedProperty.set('tooltip', tooltip);
          }
          return nestedProperty;
        });
      });
    }
    //If the property is not a group, set the tooltip if the property name matches the property name to set the tooltip for.
    else if (propertyNameToSetTooltip === property.get('name')) {
      return property.set('tooltip', tooltip);
    }
    return property;
  }).toMap();
};