import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { getInitialInvoiceQuery, PROPERTY_GROUPS_WITH_LABEL_AND_DEFINITIONS_QUERY } from '../graphQL/InvoicesEditorQueries';
import { getNonEnrolledCheckoutProperties, getEnrolledCheckoutProperties, makeNewProperty } from '../utils/properties';
import I18n from 'I18n';
import { addWarning
// @ts-expect-error untyped customer-data-ui-utilities
} from 'customer-data-ui-utilities/alerts/Alerts';
import { InvoiceOperation } from 'invoices-ui-lib/types/InvoiceOperation';
import useDefaultPaymentMethods from 'commerce-shared-components-lib/features/checkout/hooks/useDefaultPaymentMethods';
import useShippingSettings from 'commerce-shared-components-lib/features/checkout/hooks/useShippingSettings';
import { getShouldDisableCheckout } from '../utils/paymentsEligibility';
import useGetDerivedDomainAndSlug from './useGetDerivedDomainAndSlug';
import { HS_ALLOWED_PAYMENT_METHODS, HS_ALLOW_PARTIAL_PAYMENTS, HS_COLLECT_ADDRESS_TYPES, HS_CURRENCY, HS_DOMAIN, HS_ENABLE_AUTOMATED_TAXES, HS_INVOICE_PAYMENT_COLLECTION_METHOD, HS_SLUG, HS_TIMEZONE } from 'invoices-ui-lib/constants/properties';
import useGetInvoiceCustomPropertyValues from './useGetInvoiceCustomPropertyValues';
import { getInvoiceProperty } from 'invoices-ui-lib/utils/property';
import { getIsCurrencyCheckoutSupported } from '../utils/validCurrencies';
import { useQuery as useDFCQuery } from 'data-fetching-client';
import { FETCH_INVOICE_SETTINGS } from '../graphQL/SettingsQueries';
import { removeAutomatedSalesTaxLineItemProperties, getAutomatedSalesTaxProperties } from 'invoices-ui-lib/utils/automatedSalesTax';
import { usePaymentsEligibility } from 'invoices-ui-lib/hooks/usePaymentsEligibility';
import { ACCOUNTING_COMPANY_BILLING_CONTACT_ASSOCIATION } from '../constants/gates';
import { parseInitialInvoice } from '../utils/parseInitialInvoice';
export function useInitialInvoice({
  objectTypeId,
  objectId,
  operation,
  propertyBlockList,
  hasAllGates
}, {
  preloadedPaymentsEligibility,
  preloadedInitialInvoiceData,
  skip
} = {
  preloadedPaymentsEligibility: undefined,
  preloadedInitialInvoiceData: undefined,
  skip: false
}) {
  const [numCompaniesRemoved, setNumCompaniesRemoved] = useState(0);
  const [numContactsRemoved, setNumContactsRemoved] = useState(0);
  const ungatedForAccountingCompanyBillingContactAssociation = hasAllGates(ACCOUNTING_COMPANY_BILLING_CONTACT_ASSOCIATION);
  const isEditing = operation === InvoiceOperation.EDIT;
  const {
    data: initialDataFetchData,
    error: initialDataFetchError
  } = useQuery(getInitialInvoiceQuery(objectTypeId, operation), {
    variables: {
      objectId
    },
    fetchPolicy: 'network-only',
    skip: skip || !!preloadedInitialInvoiceData
  });
  const {
    paymentsEligibility
  } = usePaymentsEligibility({
    skip: skip || !!preloadedPaymentsEligibility
  });
  const derivedPaymentsEligibility = preloadedPaymentsEligibility || paymentsEligibility;
  const {
    error: allPropertyGroupsError
  } = useQuery(PROPERTY_GROUPS_WITH_LABEL_AND_DEFINITIONS_QUERY, {
    variables: {
      objectTypeId: '0-53',
      skip: !!preloadedInitialInvoiceData
    }
  });
  const {
    data: invoiceSettingsData,
    error: invoiceSettingsError
  } = useDFCQuery(FETCH_INVOICE_SETTINGS, {
    skip: skip || !!preloadedInitialInvoiceData
  });
  const useBillingContactOnQuotesAndDealsInvoices = (invoiceSettingsData === null || invoiceSettingsData === void 0 ? void 0 : invoiceSettingsData.invoiceSettings.useCompanyBillingContactAssociationForAllInvoices) || false;
  const commerceCurrencyLimits = useMemo(() => {
    return initialDataFetchData && initialDataFetchData.commerceCurrencyLimits;
  }, [initialDataFetchData]);
  const {
    data: shippingSettingsData
  } = useShippingSettings({
    fetchPolicy: 'network-only',
    skip: skip || !!preloadedInitialInvoiceData
  });
  const derivedInitialInvoice = useMemo(() => initialDataFetchData ? parseInitialInvoice(initialDataFetchData, objectTypeId, operation, setNumCompaniesRemoved, setNumContactsRemoved, ungatedForAccountingCompanyBillingContactAssociation, useBillingContactOnQuotesAndDealsInvoices, propertyBlockList, derivedPaymentsEligibility) : null, [initialDataFetchData, objectTypeId, operation, ungatedForAccountingCompanyBillingContactAssociation, propertyBlockList, useBillingContactOnQuotesAndDealsInvoices, derivedPaymentsEligibility]);
  const derivedInitialInvoiceCurrency = derivedInitialInvoice && getInvoiceProperty(derivedInitialInvoice, HS_CURRENCY);
  const {
    derivedCustomProperties,
    error: derivedCustomPropertiesError
  } = useGetInvoiceCustomPropertyValues({
    invoice: derivedInitialInvoice,
    isClone: operation === InvoiceOperation.CLONE,
    originalInvoiceId: objectId,
    skip: skip || !!preloadedInitialInvoiceData
  });
  const {
    derivedDomain,
    derivedSlug,
    hasError: derivedDomainAndSlugFetchError,
    shouldShowAlert: shouldShowDerivedDomainAlert
  } = useGetDerivedDomainAndSlug({
    invoice: derivedInitialInvoice,
    isEditing,
    skip: skip || !!preloadedInitialInvoiceData
  });
  const isDerivedInitialInvoiceCurrencySupported = getIsCurrencyCheckoutSupported(derivedInitialInvoiceCurrency, commerceCurrencyLimits || []);
  const {
    defaultPaymentMethods,
    loading: defaultPaymentMethodsLoading
  } = useDefaultPaymentMethods(derivedInitialInvoiceCurrency || '', {
    skip: skip || !derivedInitialInvoiceCurrency || !isDerivedInitialInvoiceCurrencySupported
  });
  const derivedPaymentRelatedProperties = useMemo(() => {
    if (!derivedPaymentsEligibility || !derivedInitialInvoice || !shippingSettingsData || !defaultPaymentMethods || !commerceCurrencyLimits || defaultPaymentMethodsLoading) return null;
    const shouldDisableCheckout = getShouldDisableCheckout(derivedPaymentsEligibility, commerceCurrencyLimits, derivedInitialInvoiceCurrency);
    return shouldDisableCheckout ? getNonEnrolledCheckoutProperties() : getEnrolledCheckoutProperties({
      invoice: derivedInitialInvoice,
      defaultPaymentMethods,
      shippingSettings: shippingSettingsData,
      operation
    });
  }, [commerceCurrencyLimits, defaultPaymentMethods, defaultPaymentMethodsLoading, derivedInitialInvoice, derivedInitialInvoiceCurrency, operation, derivedPaymentsEligibility, shippingSettingsData]);
  const derivedNonPaymentRelatedProperties = useMemo(() => {
    if (!derivedDomain || !derivedSlug || !derivedInitialInvoiceCurrency) return null;
    return {
      [HS_DOMAIN]: makeNewProperty(HS_DOMAIN, derivedDomain),
      [HS_SLUG]: makeNewProperty(HS_SLUG, derivedSlug),
      [HS_TIMEZONE]: makeNewProperty(HS_TIMEZONE, I18n.moment.portalTz().tz())
    };
  }, [derivedDomain, derivedSlug, derivedInitialInvoiceCurrency]);
  const initialInvoice = useMemo(() => {
    if (!derivedInitialInvoice || !derivedPaymentRelatedProperties || !derivedNonPaymentRelatedProperties || !derivedCustomProperties) return null;
    const isAutomatedSalesTaxEnabled = getInvoiceProperty(derivedInitialInvoice, HS_ENABLE_AUTOMATED_TAXES) === 'true';
    const lineItems = derivedInitialInvoice.associatedObjects.INVOICE_TO_LINE_ITEM;
    return Object.assign({}, derivedInitialInvoice, {
      properties: Object.assign({}, derivedInitialInvoice.properties, derivedPaymentRelatedProperties, derivedNonPaymentRelatedProperties, derivedCustomProperties, getAutomatedSalesTaxProperties(lineItems)),
      associatedObjects: Object.assign({}, derivedInitialInvoice.associatedObjects, {
        INVOICE_TO_LINE_ITEM: isAutomatedSalesTaxEnabled ? removeAutomatedSalesTaxLineItemProperties(lineItems) : lineItems
      })
    });
  }, [derivedInitialInvoice, derivedNonPaymentRelatedProperties, derivedPaymentRelatedProperties, derivedCustomProperties]);
  useEffect(() => {
    if (shouldShowDerivedDomainAlert) {
      addWarning('invoicesEditor.advancedSettings.domain.warning');
    }
  }, [shouldShowDerivedDomainAlert]);
  const fatalError = useMemo(() => !!(derivedDomainAndSlugFetchError || initialDataFetchError || allPropertyGroupsError || derivedCustomPropertiesError || invoiceSettingsError), [initialDataFetchError, derivedDomainAndSlugFetchError, allPropertyGroupsError, derivedCustomPropertiesError, invoiceSettingsError]);
  if (preloadedInitialInvoiceData) {
    return Object.assign({}, preloadedInitialInvoiceData, {
      error: fatalError,
      paymentsEligibility: derivedPaymentsEligibility
    });
  }
  return {
    error: fatalError,
    numCompaniesRemoved,
    numContactsRemoved,
    currencies: commerceCurrencyLimits,
    initialInvoice,
    paymentsEligibility: derivedPaymentsEligibility
  };
}