export const visit = (vm, at) => {
vm(require("UIComponents/lang/en.lyaml"));
vm(require("SharedI18nStrings/lang/countryCodes/en.lyaml"));
vm(require("SharedI18nStrings/lang/languageNames/en.lyaml"));
vm(require("ui-addon-i18n/lang/en.lyaml"));
vm(require("ui-addon-upgrades/lang/en.lyaml"));
vm(require("./phone-number-acquire/en.lyaml"));
vm(require("./business-verification/en.lyaml"));
vm(require("./twilio-translations/en.lyaml"));
at({ "en": { "calling-hubspot-numbers-acquisition-library": { "phone-number-type": { "LOCAL": "local", "MOBILE": "mobile" }, "validation-message": { "error-ada": "Error:" } } } });
};