export const visit = (vm, at) => {
vm(require("conversations-routing-lib/lang/en.lyaml"));
vm(require("calling-integration-help-desk/lang/en.lyaml"));
vm(require("./access-control/en.lyaml"));
vm(require("./alerts/en.lyaml"));
vm(require("./attachment/en.lyaml"));
vm(require("./editors-abstract/en.lyaml"));
vm(require("./editors/en.lyaml"));
vm(require("./email/en.lyaml"));
vm(require("./filtered-threads/en.lyaml"));
vm(require("./move-to-inbox/en.lyaml"));
vm(require("./new-feature-onboarding/en.lyaml"));
vm(require("./plugins-at-mentions/en.lyaml"));
vm(require("./plugins-attachments/en.lyaml"));
vm(require("./plugins-expand-communicator/en.lyaml"));
vm(require("./plugins-insert/en.lyaml"));
vm(require("./plugins-publish/en.lyaml"));
vm(require("./presence/en.lyaml"));
vm(require("./publish-message/en.lyaml"));
vm(require("./reassignment/en.lyaml"));
vm(require("./shepherds/en.lyaml"));
vm(require("./spam-filtering-and-blocking/en.lyaml"));
vm(require("./SLA/en.lyaml"));
vm(require("./tabbed-editors/en.lyaml"));
vm(require("./thread-deletion/en.lyaml"));
vm(require("./thread-history/en.lyaml"));
vm(require("./thread-toolbar/en.lyaml"));
vm(require("./message-metadata-editor/en.lyaml"));
vm(require("./message-hiding/en.lyaml"));
vm(require("ui-addon-conversations-settings/lang/en.lyaml"));
vm(require("ui-suspension-banners-lib/lang/en.lyaml"));
vm(require("conversations-message-history/lang/en.lyaml"));
vm(require("conversations-inbox-message-history/lang/en.lyaml"));
vm(require("routing-ui/lang/en.lyaml"));
at({ "en": { "conversations-thread-view": { "title": "Conversations" } } });
};