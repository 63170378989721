import styled from 'foundations-theming/utils';
import { SIDEBAR_BASE_Z_INDEX } from '../constants/SidebarConstants';
const shadowStyle = '0 0 45px rgba(0, 0, 0, 0.2)';
const CollapsableSidebar = styled.div.withConfig({
  displayName: "CollapsableSidebar",
  componentId: "j5rmlr-0"
})(["display:flex;flex-direction:column;position:absolute;z-index:", ";overflow:hidden;inline-size:", "px !important;max-inline-size:", "px !important;min-inline-size:", "px !important;block-size:100%;box-shadow:", ";transition:all 200ms cubic-bezier(0.42,0,0.58,1);border-right:1px solid ", ";"], SIDEBAR_BASE_Z_INDEX, ({
  width
}) => width, ({
  fullWidth
}) => fullWidth, ({
  collapsedWidth
}) => collapsedWidth, ({
  showShadow
}) => showShadow ? shadowStyle : 'none', ({
  theme
}) => theme.color['border-core-subtle']);
export default CollapsableSidebar;