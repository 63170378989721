// gimme: conversations-inbox-ui 06/10/2020 24a2e66917cc934433dd111db7e06fd02943ca6a
'use es6';

import styled from 'styled-components';
import { GYPSUM } from 'HubStyleTokens/colors';
const emailOverrideStyles = `
  table {
    border-spacing: 0;

    td {
      padding: 0;
    }
  }
`;
const ThreadHistoryWrapper = styled.div.withConfig({
  displayName: "ThreadHistoryWrapper",
  componentId: "sc-8veg6r-0"
})(["display:flex;flex:0 1 100%;flex-grow:1;flex-direction:column;min-height:0;background-color:", ";", ";"], GYPSUM, emailOverrideStyles);
export default ThreadHistoryWrapper;