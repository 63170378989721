/* eslint-disable hs-react-native/no-web-globals */
import { useEffect, useCallback, useState } from 'react';
export function usePageVisibility() {
  const [isPageHidden, setIsPageHidden] = useState(document.hidden);
  const handleVisibilityChange = useCallback(() => {
    setIsPageHidden(document.hidden);
  }, []);
  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, [handleVisibilityChange]);
  return !isPageHidden;
}