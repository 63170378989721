import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { getPriceProperty, getSortedMulticurrencyProperties } from 'commerce-products-lib/utils/currency';
import { PRODUCT_BY_ID_ALL_PROPERTIES_QUERY, PRODUCT_DEPENDENCIES_QUERY } from '../graphql/queries';
import { parseProductData, parseProductProperties } from '../graphql/queryParser';
export function useProductDependencies(objectId) {
  const {
    error: productDependenciesError,
    loading: productDependenciesLoading,
    data: productDependenciesData
  } = useQuery(PRODUCT_DEPENDENCIES_QUERY, {
    fetchPolicy: 'cache-and-network'
  });
  const {
    error: productCRMDataError,
    loading: productCRMDataLoading,
    data: productCRMData
  } = useQuery(PRODUCT_BY_ID_ALL_PROPERTIES_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: objectId
    }
  });
  const productData = useMemo(() => parseProductData(productCRMData), [productCRMData]);
  const productProperties = useMemo(() => {
    var _productDependenciesD;
    return parseProductProperties((_productDependenciesD = productDependenciesData === null || productDependenciesData === void 0 ? void 0 : productDependenciesData.productProperties) !== null && _productDependenciesD !== void 0 ? _productDependenciesD : []);
  }, [productDependenciesData]);
  const {
    defaultCurrencyCode,
    isMulticurrencyActive
  } = useMemo(() => {
    var _productDependenciesD2, _productDependenciesD3;
    return {
      defaultCurrencyCode: productDependenciesData === null || productDependenciesData === void 0 || (_productDependenciesD2 = productDependenciesData.currency) === null || _productDependenciesD2 === void 0 || (_productDependenciesD2 = _productDependenciesD2.homeCurrency) === null || _productDependenciesD2 === void 0 ? void 0 : _productDependenciesD2.currencyCode,
      isMulticurrencyActive: productDependenciesData === null || productDependenciesData === void 0 || (_productDependenciesD3 = productDependenciesData.currency) === null || _productDependenciesD3 === void 0 ? void 0 : _productDependenciesD3.isMulticurrencyActive
    };
  }, [productDependenciesData]);
  const errors = useMemo(() => [productDependenciesError, productCRMDataError].filter(error => error !== undefined), [productDependenciesError, productCRMDataError]);
  const currencyProperties = useMemo(() => {
    if (!defaultCurrencyCode) {
      return [];
    }
    if (!isMulticurrencyActive) {
      const priceProperty = getPriceProperty(productProperties);
      return priceProperty ? [priceProperty] : [];
    }
    return getSortedMulticurrencyProperties(productProperties, defaultCurrencyCode);
  }, [isMulticurrencyActive, productProperties, defaultCurrencyCode]);
  const isLoading = productDependenciesLoading || productCRMDataLoading;
  return {
    loading: isLoading,
    errors,
    productData,
    productProperties,
    defaultCurrencyCode,
    isMulticurrencyActive,
    currencyProperties
  };
}